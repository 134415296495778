<template>
  <div>
    <v-dialog persistent v-model="showEditClientVersionDialog" width="700">
      <v-card :loading="loading">
        <v-form
          lazy-validation
          v-model="form"
          ref="newClientVersionForm"
          :disable="loading"
        >
          <v-card-title>
            <span class="text-h6 mr-2">Edit {{ clientVersion.version }}</span>
            <v-chip
              dark
              :color="
                clientVersion.arch === 'x64' ? 'primary' : 'green darken-3'
              "
              >{{ clientVersion.arch }}</v-chip
            >
            <v-spacer></v-spacer>
            <v-btn @click="close" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="6" v-if="clientVersionPayload.arch === 'x64'">
                <v-select
                  v-model="clientVersionPayload.os_ids"
                  :items="supportedOperatingSystems"
                  :rules="[rules.required]"
                  label="Operating System"
                  :item-text="(item) => item.name + '  ' + item.version"
                  item-value="id"
                  outlined
                  small-chips
                  multiple
                  deletable-chips
                  chips
                  hide-details="auto"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip :color="item.color" small>{{
                      item.name + ' ' + item.version
                    }}</v-chip>
                  </template>
                </v-select>
              </v-col>

              <v-col v-if="clientVersionPayload.arch === 'arm64'" cols="6">
                <v-select
                  v-model="clientVersionPayload.jetpack_ids"
                  :items="jetpackVersionList"
                  :rules="[rules.required]"
                  label="Supported jetpacks of new tag"
                  :item-text="
                    (item) =>
                      'L4T: ' +
                      item.l4t +
                      ' / ' +
                      'Jetpack Version: ' +
                      item.version
                  "
                  item-value="id"
                  outlined
                  small-chips
                  multiple
                  deletable-chips
                  chips
                  hide-details="auto"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip :color="item.color" small>{{
                      'Jetpack: ' + item.version
                    }}</v-chip>
                  </template>
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <span>{{ 'Jetpack: ' + item.version }}</span>
                            <v-spacer></v-spacer>
                            <v-chip small color="primary">{{
                              'L4T: ' + item.l4t
                            }}</v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="clientVersionPayload.version"
                  :rules="[rules.required]"
                  auto-select-first
                  label="*Cordatus Version"
                  color="deep-purple"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="clientVersionPayload.shasum"
                  :rules="[rules.required]"
                  color="deep-purple"
                  label="*Shasum"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="clientVersionPayload.changelog"
                  outlined
                  label="*Change Log"
                  hint="Type changes of new client version.."
                  hide-details="auto"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="editClientVersion()"
                :disabled="!form || loading"
                color="primary"
              >
                SAVE
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'
import { apiService } from '../../../services/api.service'

export default {
  computed: {
    ...mapGetters(['supportedOperatingSystems', 'jetpackVersionList'])
  },

  props: {
    showEditClientVersionDialog: { type: Boolean, default: false },
    clientVersion: {
      type: Object,
      default: () => null
    }
  },

  data: () => ({
    loading: false,
    form: true,
    clientVersionPayload: {
      cordatus_client_id: null,
      version: '',
      arch: '',
      shasum: '',
      dev: true,
      changelog: '',
      os_ids: [],
      jetpack_ids: []
    },
    rules: {
      length: (len) => (v) =>
        (v || '').length <= len || `Name length can max ${len}.`,
      required: (v) => !!v || 'This field is required',
      numberRule: (v) => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true
        return 'Number has to be between 0 and 9'
      }
    }
  }),

  created () {
    this.getVersionList()
    this.copyClientVersion()
  },

  methods: {
    async editClientVersion () {
      if (this.$refs.newClientVersionForm.validate()) {
        this.loading = true
        await apiService
          .put('admin/client_version', this.clientVersionPayload)
          .then((res) => {
            this.loading = false
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'New client version added successfully'
            })
            this.$emit('fetchClientVersion')
            this.close()
          })
          .catch((err) => {
            this.loading = false
            console.log('ERR', err.response)
            Swal.fire({
              icon: 'error',
              title: 'Failed edit client version',
              text: err.response.data.message
            })
          })
      }
    },
    getVersionList () {
      if (this.supportedOperatingSystems.length === 0) {
        this.$store.dispatch('getSupportedOperatingSystem')
      }

      if (this.jetpackVersionList.length === 0) {
        this.$store.dispatch('getJetpackVersionList')
      }
    },
    copyClientVersion () {
      this.clientVersionPayload.cordatus_client_id = this.clientVersion.id
      this.clientVersionPayload.arch = this.clientVersion.arch
      this.clientVersionPayload.version = this.clientVersion.version
      this.clientVersionPayload.changelog = this.clientVersion.changelog
      this.clientVersionPayload.dev = this.clientVersion.dev
      this.clientVersionPayload.dev = this.clientVersion.dev
      this.clientVersionPayload.shasum = this.clientVersion.shasum

      this.clientVersionPayload.os_ids = this.clientVersion.supported_os.map(
        (item) => item.id
      )

      this.clientVersionPayload.jetpack_ids =
        this.clientVersion.jetpack_versions.map((item) => item.id)
    },

    close () {
      this.$emit('closeEditClientVersionDialog')
    }
  }
}
</script>

  <style>
</style>
