<template>
  <div>
    <v-dialog persistent v-model="showNewClientVersionDialog" width="700">
      <v-card :loading="loading">
        <v-form
          lazy-validation
          v-model="form"
          ref="newClientVersionForm"
          :disable="loading"
        >
          <v-card-title>
            <span class="text-h6">New Client Version</span>
            <v-spacer></v-spacer>
            <v-btn @click="close" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <span>Select Architecture</span>
                <v-radio-group class="mt-0" row v-model="archType">
                  <v-radio label="Jetson" value="arm64"></v-radio>
                  <v-radio label="Workstation" value="x64"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="6" v-if="archType === 'x64'">
                <v-select
                  v-model="clientVersionPayload.os_ids"
                  :items="osList"
                  :rules="[rules.required]"
                  label="Operating System"
                  :item-text="(item) => item.name + '  ' + item.version"
                  item-value="id"
                  outlined
                  small-chips
                  multiple
                  deletable-chips
                  chips
                  hide-details="auto"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip :color="item.color" small>{{
                      item.name + ' ' + item.version
                    }}</v-chip>
                  </template>
                </v-select>
              </v-col>

              <v-col v-if="archType === 'arm64'" cols="6">
                <v-select
                  v-model="clientVersionPayload.jetpack_ids"
                  :items="jetpacks"
                  :rules="[rules.required]"
                  label="Supported jetpacks of new tag"
                  :item-text="
                    (item) =>
                      'L4T: ' +
                      item.l4t +
                      ' / ' +
                      'Jetpack Version: ' +
                      item.version
                  "
                  item-value="id"
                  outlined
                  small-chips
                  multiple
                  deletable-chips
                  chips
                  hide-details="auto"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip :color="item.color" small>{{
                      'Jetpack: ' + item.version
                    }}</v-chip>
                  </template>
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <span>{{ 'Jetpack: ' + item.version }}</span>
                            <v-spacer></v-spacer>
                            <v-chip small color="primary">{{
                              'L4T: ' + item.l4t
                            }}</v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="clientVersionPayload.version"
                  :rules="[rules.required]"
                  auto-select-first
                  label="*Cordatus Version"
                  color="deep-purple"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="clientVersionPayload.shasum"
                  :rules="[rules.required]"
                  color="deep-purple"
                  label="*Shasum"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="clientVersionPayload.changelog"
                  outlined
                  label="*Change Log"
                  hint="Type changes of new client version.."
                  hide-details="auto"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="newClientVersion()"
                :disabled="!form || loading"
                color="primary"
              >
                SAVE
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'

export default {
  props: {
    showNewClientVersionDialog: { type: Boolean, default: false }
  },

  data: () => ({
    loading: false,
    form: true,
    archType: 'arm64',
    clientVersionPayload: {
      os_ids: [],
      version: '',
      arch: '',
      shasum: '',
      changelog: '',
      jetpack_ids: [],
      dev: true
    },
    osList: [],
    jetpacks: [],
    rules: {
      length: (len) => (v) =>
        (v || '').length <= len || `Name length can max ${len}.`,
      required: (v) => !!v || 'This field is required',
      numberRule: (v) => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true
        return 'Number has to be between 0 and 9'
      }
    }
  }),

  created () {
    this.getJetpacks()
    this.getOperatingSystemList()
  },

  methods: {
    newClientVersion () {
      if (this.$refs.newClientVersionForm.validate()) {
        this.clientVersionPayload.arch = this.archType
        this.checkArchType()
        this.loading = true
        apiService
          .post('admin/client_version', this.clientVersionPayload)
          .then((res) => {
            this.loading = false
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'New client version added successfully'
            })
            this.$emit('fetchClientVersion')
            this.$store.dispatch('setNonNotifiedClientVersion', res.data.new_version.id)
            this.close()
          })
          .catch((err) => {
            this.loading = false
            console.log('Error while adding new client version: ', err.response)
            Swal.fire({
              icon: 'error',
              title: 'Failed get client version',
              text: err.response.data.message
            })
          })
      }
    },
    checkArchType () {
      if (this.archType === 'arm64') {
        this.clientVersionPayload.os_ids = []
      } else {
        this.clientVersionPayload.jetpack_ids = []
      }
    },
    async getJetpacks () {
      this.loading = true
      await apiService
        .get('admin/jetpacks')
        .then((res) => {
          this.loading = false
          this.jetpacks = res.data.data
        })
        .catch((err) => {
          this.loading = false
          Swal.fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    },
    async getOperatingSystemList () {
      await apiService
        .get('admin/supported_operating_system')
        .then((res) => {
          this.osList = res.data.data
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Failed get supported os list.',
            text: err.response.data.message
          })
        })
    },
    close () {
      this.$emit('closeNewClientVersionDialog')
    }
  }
}
</script>

  <style>
</style>
